body{
  font-size:14px;
  
}
.wrap{
  width: 375px;
}
.page-content{
  background: $bodyBgColor;
  padding-bottom: 0.375rem;

}
.card-tip {
  font-size:16px;
  margin-top:10px;
  text-align: center;
  opacity: 0.8;
}
.content-bg{
  background-image: url('../../assets/images/frontColor.png');
  background-repeat: repeat;
}
.content-bg-light {
  background-image: url('../../assets/images/bg-image-light.jpg');
  background-repeat: repeat;
}
.worring-color {
  .environ-value-num {
    color:red !important
  }
}
.normal-color {
  color:green;
}
.hide{
  display: none;
}
::-webkit-scrollbar {
  display: none;
  }
