@import '../../assets/styles/variables.scss';
@import '../../assets/styles/reset.scss';
@import '../../assets/styles/global.scss';

.card-content{
  padding-top:5px;
  padding-bottom: 15px;
  width: 200px;
  border-radius: 8px;
  background-image: url('../../assets/images/frontColor.png');
  background-repeat: repeat;
  margin-bottom: 10px;;
  border: #474343 solid 1px;
  //box-shadow:0 40px 20px 0 rgba(170, 169, 233, 0.5);
  .card-title {
    width: 100%;
    height: 37px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 0 0 0 10px;
    .card-title-icon {
      width: 40px;
      height: 40px;
     
    }
    .card-title-text {
      width: 110px;
      font-size:15px;
      margin-left: 5px;
      flex:1
    }
    .card-title-arrow {
      height: 35px;
      width: 35px;
      background-size: 40%;
      background-position: center;
      background-image: url('../../assets/images/arrow.png');
      background-repeat: no-repeat;
      transform: rotate(-180deg);
    }
  }
}



