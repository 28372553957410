@import '../../assets/styles/variables.scss';
@import '../../assets/styles/reset.scss';
@import '../../assets/styles/global.scss';

.wrap {
  max-width: 640px;
  margin: 0 auto;
  width: 100%;
  color: #fff;
  .module-content {
    margin: 0 auto;
    width: 350px;
    display: grid;
    grid-template-columns: repeat(2, 170px);
    grid-template-rows: auto auto;
    box-sizing: border-box;
    justify-items: center;
    justify-content: center;
    grid-column-gap: 10px;
  }
}
.live-content {
  display: grid;
  justify-content: center;
  margin: 0 auto 20px auto;
}
.live-pic {
  height: 100px;
  width: 100%;
  background-image: url("../../assets/images/live-pic3.png");
  background-size: 100%;
  background-position: center;
}
