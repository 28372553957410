@import '../../assets/styles/variables.scss';
@import '../../assets/styles/reset.scss';
@import '../../assets/styles/global.scss';

.not-found-box {
  width: 100%;
  height: 700px;
  overflow: hidden;
  text-align: center;
  color: #999;
  .not-found-content{
    height: 30rem;
    width: 30rem;
    margin: 40rem auto 3rem auto;
    background-position: center;
    background-repeat: no-repeat;;
    background-image: url('../../assets/images/404.png');
  }
  p{
    margin-bottom:10rem;
  }
}