@import '../../assets/styles/variables.scss';
@import '../../assets/styles/reset.scss';
@import '../../assets/styles/global.scss';

.mini-card-box{
  border: #474343 solid 1px;
  &.sm{
    width: 170px;
    height: 80px;
    grid-template-columns: 70px 100px;
    .text-content{
       flex-direction: column;
       justify-content: space-around;
    }
  }
  &.lg{
    width: 350px;
    height: 70px;
    grid-template-columns: 70px 260px;
    .text-content{
      width: 260px;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      .text-content-top{
        .unit{
          font-size:22px
        }
      }
      .text-content-bottom{
        font-size:16px

      }

    }
  }
  box-sizing: border-box;

  border-radius: 8px;
  display: grid;
  align-items: center;
  justify-items: start;
  margin-bottom: 10px;
  background-image: url('../../assets/images/frontColor.png');
  background-repeat: repeat;
  .icon-circle-content {
    height:50px;
    width: 50px;
    border-radius: 100%;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-content{
    width: 150px;
    font-size: 14px;
    height: 50px;
    display: flex;
    color: #eee;
    opacity: 0.8;
    .text-content-top {
      box-sizing: border-box;
      box-sizing: border-box;
      display: flex;
      align-items: baseline;
      .value {
        font-size:22px;
        font-weight: bold;
        margin-right: 5px;
      }
    }
  }
}