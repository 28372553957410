@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/reset.scss';
@import '../../../assets/styles/global.scss';

.live-container {

  background-image: url('../../../assets/images/frontColor.png');
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .p-line {
    height: 100px;
    width: 100%;
    background-image: url('../../../assets/images/live-pic3.png');
    background-size: 100%;
    background-position: center;
    margin-top: 30px;
  }

  .btn-list {
    display: flex;
    margin-top: 0.375rem;
    padding-left: 0.375rem;
  }
}

.btn-content {
  position: relative;
  margin-bottom: 25px;
}

.middle-btn {
  display: flex;
}

// .btn-content:after{
//   content: '';
//   position: absolute;
//   height: 57px;
//   width: 1px;
//   background:#EEE;
//   right:37px;
// }
// .tjz-btn:after{
//   right: 56px;
//   height: 37px;
// }
// .tcljz-btn:after{
//   right: 70px;
//   height: 52px
// }
// .qbdm-btn:after {
//   right: 70px;
//   height: 60px;
// }
// .tcljy-btn:after{
//   top:-70px;
//   right: 56px;
//   height:70px;
// }
// .tjy-btn:after{
//   top:-100px;
//   right: 70px;
//   height:100px;
// }