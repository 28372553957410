@import '../../assets/styles/variables.scss';
@import '../../assets/styles/reset.scss';
@import '../../assets/styles/global.scss';

.foot-content {
  padding-left: 0.5rem;
  line-height: 0.75rem;
  a{
    color: #fff;
  }
}