@import '../../assets/styles/variables.scss';
@import '../../assets/styles/reset.scss';
@import '../../assets/styles/global.scss';


.header-content {
  color: $titleColor;
  height:45px;
  width: 375px;
  display: grid;
  grid-template-columns: 60px 240px 60px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  .page-name {
    font-size: 18px;
  }


}
