@import '../../assets/styles/variables.scss';
@import '../../assets/styles/reset.scss';
@import '../../assets/styles/global.scss';

.testing-content {
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;

  .test-image {
    width: auto;
    height: 15rem;
  }

  .texting-title {
  }
  // > div {
  //   width: 44rem;
  // }
  .testing-content-right {
    // font-size: 30rem;
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: seagreen;
  }
  .not-good {
    color: red;
  }
}
.testing-content-left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.test-image {
  height: 30rem;
  width: 30rem;
}

.only-video {
  height: 31rem;
}
