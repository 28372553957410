@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/reset.scss';
@import '../../../assets/styles/global.scss';


.head-message-content {
  border: #474343 solid 1px;
  box-sizing: border-box;
  height: 40px;
  width: 350px;
  background-image: url('../../../assets/images/frontColor.png');
  margin: 10px auto;
  border-radius: 8px;
  font-size:16px;
  box-sizing: border-box;
  padding:15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .message-item {
    display: grid;
    grid-template-columns: 100px 200px;
  
  }
}