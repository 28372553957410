@import '../../assets/styles/variables.scss';
@import '../../assets/styles/reset.scss';
@import '../../assets/styles/global.scss';

.block-title-box{
  height: 40px;
  font-size:16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

}
.block-title-box:before {
  content:'';
  background: $lineColor;
  height: 1px;
  width: 50px;
  position: absolute;
  left: 50px;
}
.block-title-box:after {
  content:'';
  background:$lineColor;
  height: 1px;
  width: 60px;
  position: absolute;
  right:60px
}