@import '../../assets/styles/variables.scss';
@import '../../assets/styles/reset.scss';
@import '../../assets/styles/global.scss';

.time-period-selector-box {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;

  .selector-item {
    color: #eee;
    font-size: 14px;
    margin-left:10px;
    min-width: 65px;
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    >span{
      margin-right: 10px;;
    }
  }

  .active {
    color: rgb(60, 144, 223);
    font-weight: bold;
    font-size:16px;
  }
}
.popover {
  border-radius:4px;
  width: 120px;
  position:absolute;
  top:40px;
  z-index: 999;
  left: -20px;
  .popover-item{
    padding-left: 10px;
    height: 30px;
    color: #eee;
    width: 100px;
    margin: 0 auto;
    border-bottom: 1px solid rgba(117, 117, 117, 0.5);
    font-size:13px
  }
  >div:nth-last-child(1) {
    border-style: none;
  }
}
.popover-arrow{
  height: 15px;
  width: 15px;
  position: absolute;
  top: -8px;
  left: 30px;
  background-image: url(../../assets/images/qpsjx.png);
  background-size:contain;
  background-repeat: no-repeat;;
}

.mask{
  width: 375px;
  height: 10000px;
  background:rgba(0,0,0,0.7);
  position: fixed;
  left: 50%;
  margin-left: -180px;
  top:0;
  z-index:998;
}