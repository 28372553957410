@import '../../assets/styles/variables.scss';
@import '../../assets/styles/reset.scss';
@import '../../assets/styles/global.scss';

.cell-group-box {
  border: #474343 solid 1px;
  width: 350px;
  border-radius: 6px;
  font-size:14px;
  .cell-group-item{
    &.error{
      color:red
    }
    &.normal {
          
    }
    margin: 0 auto;
    height: 40px;
    width: 320px;
    display: grid;
    border-bottom: 1px solid $lineColor;
    align-items: center;
    grid-template-columns: 20px 200px 100px;
    .cell-group-item-status {
      text-align:right;
    }
  }
  .cell-group-item:nth-last-child(1) {
    border-bottom: none;
  }
}