@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/reset.scss';
@import '../../../assets/styles/global.scss';

.environ-content {
  margin: 0 auto;
  width: 90%;
  height: 160px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2,80px);
  grid-template-rows: repeat(2,70px);
  position: relative;
  justify-items: center;
  align-items: center;
  .environ-item {
    .environ-value {
      font-size: 18px;
       display: flex;
      //  color:#41ff7d;
      color: #fff;
       align-items: baseline;
       .environ-value-num {
         color: #41ff7d;
       }
       .environ-value-util {
         font-size:14px
       }
    }
    .environ-title {
      text-align: center;
      height: 20px;
      font-size: 14px;
    }
  }
} 
.environ-content::before {
  content:'';
  position: absolute;
  background:rgba(255,255,255,0.5);
  height: 150px;
  width: 1px;
  left: 50%;
  top:0px;
}
.environ-content::after{
  content:'';
  position: absolute;
  background:rgba(255,255,255,0.5);
  width: 150px;
  height: 1px;
  top:70px;
}

